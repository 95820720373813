import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Section from '../components/section';
import ServiceCard from '../components/service-card';

export default function Services({ data }) {
  const pageInfo = {
    id: 'services-page',
    url: '/services',
    title: 'Услуги и цены',
    description: 'Услуги и цены КМК клиники.',
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'Услуги и цены',
        link: null,
      },
    ],
  };

  const pagesByRows = [[]];

  for (let i = 0, j = 0; i < data.allStrapiService.edges.length; i += 1) {
    if (!(j < 4)) {
      pagesByRows.push([]);
      j = 0;
    }

    pagesByRows[pagesByRows.length - 1].push(data.allStrapiService.edges[i]);
    j += 1;
  }

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        {
          pagesByRows.map((row) => (
            <div className="row clearfix">
              {
                row.map((page) => (
                  <div className="column column--1-of-4">
                    <ServiceCard service={{ ...page.node }} />
                  </div>
                ))
              }
            </div>
          ))
        }
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    allStrapiService(sort: { fields: weight, order: ASC }) {
      edges {
        node {
          strapiId
          slug
          title
          description
          cover {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
